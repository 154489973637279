import React, { useState, useEffect } from 'react';
import { getCitationFilePath } from '../../api';
import { getPdfPath } from '../../helpers/string-helper';
import { Callout, DirectionalHint } from '@fluentui/react';
import { marked } from 'marked';
import styles from "./TypingEffect.module.css";

interface TypingEffectProps {
    sanitizedAnswerHtml: string;
    onCitationClicked: (citationFilePath: string, sourcePath: string, pageNumber: string) => void;
    onTypingEnd: () => void;
    onSetMetadata: (metadata: any) => void;
    calloutRender: any;
}

const TypingEffect: React.FC<TypingEffectProps> = ({ sanitizedAnswerHtml, onCitationClicked, onTypingEnd, onSetMetadata, calloutRender }) => {
    const [displayedHtml, setDisplayedHtml] = useState<string>('');
    const [hoverTargetElement, setHoverTargetElement] = useState();
    const [calloutRenderState, setCalloutRender] = useState();
    const [calloutVisible, setCalloutVisible] = useState<boolean>(false);
    const [typingEnd, setTypingEnd] = useState<boolean>(false);
    const [metadata, setMetadata] = useState<any>();
    const [htmlContent, setHtmlContent] = useState<string>('');

    useEffect(() => {
        const convertMarkdownToHtml = async (markdown: string): Promise<string> => {
            return marked(markdown);
        };

        const updateHtmlContent = async () => {
            const convertedHtml = await convertMarkdownToHtml(sanitizedAnswerHtml);
            setHtmlContent(convertedHtml);
            setDisplayedHtml('');
        };

        updateHtmlContent();
    }, [sanitizedAnswerHtml]);

    useEffect(() => {
        if (htmlContent) {
            let index = 0;
            const interval = setInterval(() => {
                const nextChar = htmlContent.charAt(index);
                
                if (nextChar === '<') {
                    const nextTagEndIndex = htmlContent.indexOf('>', index); 
                    const tag = htmlContent.substring(index, nextTagEndIndex + 1);
                    setDisplayedHtml(prevHtml => prevHtml + tag);
                    index = nextTagEndIndex + 1;
                } else {
                    setDisplayedHtml(prevHtml => prevHtml + nextChar);
                    index++;
                }

                if (index >= htmlContent.length) {
                    clearInterval(interval);
                    onTypingEnd();
                    setTypingEnd(true);
                }
            }, 1); 

            return () => clearInterval(interval);
        }
    }, [htmlContent]);

    useEffect(() => {
        if (!!metadata) {
            onSetMetadata(metadata);
        }
    }, [metadata]);

    useEffect(() => {
        calloutRender && setCalloutRender(calloutRender);
    }, [calloutRender]);

    return (
        <>
            <div
                className={`${styles.answerText} ${styles.typingText}`}
                dangerouslySetInnerHTML={{ __html: displayedHtml }}
                onClick={(e: any) => {
                    const targetElement = e.target.parentElement.dataset;
                    const path = decodeURIComponent(decodeURI(targetElement.path)).split("/").slice(4).join("/");
                    
                    if (e.target.className?.includes('citation') || e.target.localName?.toLowerCase().includes('sup'))
                        onCitationClicked(getCitationFilePath(path), (targetElement.sourcepath as any), (targetElement.pagenumber as any));
                }}
                onMouseOver={(e: any) => {
                    setHoverTargetElement(undefined);
                    setCalloutRender(undefined);
                    const targetElement = e.target.parentElement.dataset;
                    const path = decodeURIComponent(decodeURI(targetElement.path)).split("/").slice(4).join("/");

                    if (e.target.className?.includes('citation') || e.target.localName?.toLowerCase().includes('sup') && typingEnd) {
                        setHoverTargetElement(e.target);
                        setCalloutVisible(true);
                        setMetadata({
                            path: getPdfPath(path),
                            page: targetElement.pagenumber,
                            dataPublicacao: targetElement.dtpublicacao,
                            dataJulgamento: targetElement.dtjulgamento,
                            tribunal: targetElement.tribunal,
                            isprecedente: targetElement.isprecedente,
                            tema: targetElement.tema,
                            processoTitulo: targetElement.processotitulo,
                        });
                    }
                }}
            />
            <Callout
                target={hoverTargetElement}
                hidden={!calloutVisible}
                directionalHint={DirectionalHint.bottomCenter}
                onDismiss={() => setCalloutVisible(false)}
                onMouseLeave={() => setCalloutVisible(false)}
            >
                {calloutRenderState}
            </Callout>
        </>
    );
};

export default TypingEffect;