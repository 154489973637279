import { createTheme } from "@fluentui/react";

export const customTheme = createTheme({
	palette: {
		themePrimary: '#4f52b2',
		themeLighterAlt: '#f9f9fe',
		themeLighter: '#e6e7fa',
		themeLight: '#d2d3f6',
		themeTertiary: '#a7a9ed',
		themeSecondary: '#8285e4',
		themeDarkAlt: '#676aca',
		themeDark: '#575aab',
		themeDarker: '#40427e',
		neutralLighterAlt: '#faf9f8',
		neutralLighter: '#f3f2f1',
		neutralLight: '#edebe9',
		neutralQuaternaryAlt: '#e1dfdd',
		neutralQuaternary: '#d0d0d0',
		neutralTertiaryAlt: '#c8c6c4',
		neutralTertiary: '#a19f9d',
		neutralSecondary: '#605e5c',
		neutralSecondaryAlt: '#8a8886',
		neutralPrimaryAlt: '#3b3a39',
		neutralPrimary: '#323130',
		neutralDark: '#201f1e',
		black: '#000000',
		white: '#ffffff',
	}});