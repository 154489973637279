import { authentication } from "@microsoft/teams-js";

export async function teamsAuth(silent: boolean) {
    return new Promise((resolve, reject) => {
        const teamsAuthTokenRequest = {
            silent: silent,
            successCallback: (token: string) => {
                resolve({
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                })
            },
            failureCallback: (error: string) => {
                console.log(`ERROR: ${error}`);
            },
        };

        authentication.getAuthToken(teamsAuthTokenRequest);
    });
}