import { useEffect } from 'react';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Callout, INavLinkGroup, Nav, Persona, PersonaSize, Text } from "@fluentui/react";
import { TFunction } from 'i18next';
import { MsalObj, UserProfile, getAzureB2CData, getAzureB2CEndpoint, getMsalData } from '../../api';
import { PublicClientApplication } from '@azure/msal-browser';
import styles from "./ProfilePicker.module.css";

interface Props {
    userData: UserProfile;
    t: TFunction;
}

enum Idp {
    google = "google.com",
    ad = "AzureAd",
}

export const ProfilePicker = ({ userData, t }: Props) => {
    const buttonId = useId('callout-profile');
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const items: INavLinkGroup[] = [
        {
            links: [
                {
                    name: 'Sair',
                    url: '',
                    icon: 'SignOut',
                    key: 'logout',
                    title: 'Sair',
                    onClick: () => {
                        void getAzureB2CEndpoint().then(async (res: any) => {
                            const logoutRedirectUri = res;

                            if (userData.idp == Idp.ad) {
                                void getMsalData().then(async (res: MsalObj) => {
                                    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
                                    const msalObj = {
                                        auth: {
                                            clientId: res.clientId,
                                            authority: res.authority,
                                            redirectUri: window.location.origin,
                                            postLogoutRedirectUri: window.location.origin + "/signout",
                                        },
                                        cache: {
                                            cacheLocation: 'localStorage',
                                            storeAuthStateInCookie: isIE,
                                        }
                                    }

                                    const msalInstance = new PublicClientApplication(msalObj);
        
                                    await msalInstance.initialize();
                                    await msalInstance.handleRedirectPromise()
                                        .then(() => {
                                            window.localStorage.removeItem("authType"); 
                                            msalInstance.clearCache();
                                            msalInstance.logoutRedirect({ account: msalInstance.getAllAccounts()[0] });
                                        });
                                });
                            } else {
                                void getAzureB2CData().then(async (res: MsalObj) => {
                                    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
                                    const msalObj = {
                                        auth: {
                                            clientId: res.clientId,
                                            authority: res.authority,
                                            knownAuthorities: res.knownAuthorities,
                                            postLogoutRedirectUri: logoutRedirectUri,
                                        },
                                        cache: {
                                            cacheLocation: 'localStorage',
                                            storeAuthStateInCookie: isIE,
                                        }
                                    }
        
                                    const msalInstance = new PublicClientApplication(msalObj);
        
                                    await msalInstance.initialize();
                                    await msalInstance.handleRedirectPromise()
                                        .then(() => {
                                            window.localStorage.removeItem("authType"); 
                                            msalInstance.clearCache();
                                            msalInstance.logoutRedirect({ account: msalInstance.getAllAccounts()[0] });
                                        });
                                });
                            }
                        });
                    }
                },
            ],
        },
    ];

    return (
        <div className={styles.profileContainer}>
            <Persona
                id={buttonId}
                onClick={toggleIsCalloutVisible}
                className={styles.avatar}
                text={userData.name}
                size={PersonaSize.size32}
                hidePersonaDetails={true}
                initialsColor="#4f52b2" />
            {isCalloutVisible && (
                <Callout
                    className={styles.profileCallout}
                    role="dialog"
                    gapSpace={0}
                    target={`#${buttonId}`}
                    onDismiss={toggleIsCalloutVisible}
                    setInitialFocus
                    directionalHint={6}
                    isBeakVisible={false}
                >
                    <div className={styles.profileCalloutContainer}>
                        <div className={styles.container}>
                            {userData.idp == Idp.google ?
                                <img height="25px" src="https://jurisprudenciagptb2c.blob.core.windows.net/templates/src/idp_logos/colored/google.svg" />
                                :
                             userData.idp == Idp.ad ?
                                <img height="25px" src="https://jurisprudenciagptb2c.blob.core.windows.net/templates/src/idp_logos/colored/Microsoft_365.logo.svg" />
                                :
                                <img height="25px" src="https://jurisprudenciagptb2c.blob.core.windows.net/templates/src/idp_logos/colored/microsoft.svg" />
                            }

                            <div className={styles.textContainer}>
                                <Text className={styles.profileName} variant={'medium'}>{userData.name}</Text>
                                {userData.email &&
                                    <Text variant={'smallPlus'}>{userData.email}</Text>
                                }
                            </div>
                        </div>
                        

                        <Nav className={styles.nav} groups={items} />
                    </div>
                </Callout>
            )}
        </div>
    );
};
