import { AuthenticationResult, InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import { MsalObj, getMsalData, getAzureB2CData } from "../api";
import { EWebAuthType } from "../pages/app/App";

export async function msalAuth() {
    const authType = window.localStorage.getItem("authType") == EWebAuthType.B2C ? EWebAuthType.B2C : EWebAuthType.AD

    if (authType == EWebAuthType.B2C) {
        return await getAzureB2CData().then(async (res: MsalObj) => {
            const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
            const msalObj = {
                auth: {
                    clientId: res.clientId,
                    authority: res.authority,
                    knownAuthorities: res.knownAuthorities
                },
                cache: {
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: false,
                }
            }

            const msalInstance = await PublicClientApplication.createPublicClientApplication(msalObj);
            const accounts = msalInstance.getAllAccounts();

            const idTokenClaims = accounts[0].idTokenClaims;
            const forceRefresh = (new Date(idTokenClaims?.exp! * 1000) <= new Date());

            const silentRequest = { 
                scopes: res.scopes.split(" "), 
                account: accounts[0],
                forceRefresh: true, 
            }
            
            if (accounts?.length > 0) {
                return await msalInstance.acquireTokenSilent(silentRequest)
                    .then((res: AuthenticationResult) => {
                        return {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${res.idToken}`,
                        }
                    })
                    .catch(async (error) => {
                        //Acquire token silent failure, and send an interactive request
                        if (error instanceof InteractionRequiredAuthError) {
                            return await msalInstance.acquireTokenRedirect(silentRequest);
                        }
                    })
            } else {
                return await msalInstance.handleRedirectPromise()
                    .then((msalRes: AuthenticationResult | null) => {
                        if (msalRes) {
                            window.location.href = "/"
                        } else {
                            msalInstance.loginRedirect({ scopes: res.scopes.split(" ") })
                        }
                    });
            }
        });
    } else {
        return await getMsalData().then(async (res: MsalObj) => {
            const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
            const msalObj = {
                auth: {
                    clientId: res.clientId,
                    authority: res.authority,
                    redirectUri: window.location.origin,
                },
                cache: {
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: isIE,
                }
            }
    
            const msalInstance = await PublicClientApplication.createPublicClientApplication(msalObj);
            const accounts = msalInstance.getAllAccounts();
    
            const idTokenClaims = accounts[0].idTokenClaims;
            const forceRefresh = (new Date(idTokenClaims?.exp! * 1000) <= new Date()); 
    
            const silentRequest = { 
                scopes: res.scopes.split(" "), 
                account: accounts[0],
                forceRefresh: forceRefresh, 
            }
    
            if (accounts?.length > 0) {
                return await msalInstance.acquireTokenSilent(silentRequest)
                    .then((res: AuthenticationResult) => {
                        return {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${res.idToken}`,
                        }
                    })
                    .catch(async (error) => {
                        //Acquire token silent failure, and send an interactive request
                        if (error instanceof InteractionRequiredAuthError) {
                            return await msalInstance.acquireTokenRedirect(silentRequest);
                        }
                    })
            } else {
                return await msalInstance.handleRedirectPromise()
                    .then((msalRes: AuthenticationResult | null) => {
                        if (msalRes) {
                            window.location.href = "/"
                        } else {
                            msalInstance.loginRedirect({ scopes: res.scopes.split(" ") })
                        }
                    });
            }
        });
    }
}